import React from 'react';
import VideoCover from 'react-video-cover';
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps";
import logo from './naaylogo.svg';
import Video from './video.mp4';
import './App.css';

const mapStyles =

[
    {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "hue": "#bc00ff"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#e8b8f9"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "labels",
        "stylers": [
            {
                "color": "#ff0000"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#3e114e"
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "color": "#a02aca"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "color": "#2e093b"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "labels.text",
        "stylers": [
            {
                "color": "#9e1010"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#ff0000"
            }
        ]
    },
    {
        "featureType": "landscape.natural.landcover",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "color": "#58176e"
            }
        ]
    },
    {
        "featureType": "landscape.natural.landcover",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "color": "#a02aca"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#d180ee"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "color": "#a02aca"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "color": "#ff0000"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text",
        "stylers": [
            {
                "color": "#a02aca"
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#cc81e7"
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "hue": "#bc00ff"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#6d2388"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#c46ce3"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#b7918f"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#280b33"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "color": "#a02aca"
            }
        ]
    }
]



const MyMapComponent = withScriptjs(withGoogleMap((props) => (
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{
      lat: 32.0145837,
      lng: 35.8329708
    }}
    defaultOptions={{
      styles: mapStyles
    }}>
  {
    props.isMarkerShown && <Marker position={{
          lat: 32.015142,
          lng: 35.833580
        }}/>
  }
</GoogleMap>)))

const BackgroundVideo = () => {
  const videoOptions = {
    src: Video,
    title: 'click to play/pause',
    autoPlay: true,
    muted: true,
    loop: true
  };
  return (<div className="App">
    <header>
      <img height={70} src={logo} alt="Naay Logo"/>
    </header>
    <div className="heroContainer">
      <div style={{
          width: '100vw',
          position: 'absolute',
          height: '100vh',
          overflow: 'hidden'
        }}>
        <VideoCover videoOptions={videoOptions}/>
      </div>
      <div className="shadeVideo"></div>
      <div className="textContainerTitle">
        <p>We do not remember the days, but rather the
          <br></br>
          <b>moments</b>
        </p>
      </div>
      <div className="socailContainer">
        <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/naay.events/" className="icon"><i className="fa fa-instagram"></i></a>
        <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/naay.events/" className="icon"><i className="fa fa-facebook"></i></a>
      </div>
    </div>
    <div className="contactContainer">
      {/*
      <div className="content">
        <div className="card">
          <p>Facebook</p>
          <p>naay.events</p>
        </div>
        <div className="card">
          <p>Instagram</p>
          <p>naay.events</p>
        </div>
        <div className="card">
          <p>Number</p>
          <p>+962 7979 37795</p>
        </div>
      </div>
      */
      }
      <div className="mapContainer">
        <MyMapComponent isMarkerShown="isMarkerShown" googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCJGzCMmpp6thPmFfa5MbqbqOXAGCpR6dg&callback=initMap" loadingElement={<div style = {{ height: `100%` }}/>} containerElement={<div style = {{ height: 400, width: '100%'}}/>} mapElement={<div style = {{ height: `100%` }}/>}/>
      </div>
    </div>
    <footer>
      <p>Powered by
        <a rel="noopener noreferrer" target="_blank" href="https://oryx-t.com"> ORYX Technology Services © 2020</a>
      </p>
    </footer>
  </div>);
}

export default BackgroundVideo;
